import helpers from "@/lib/calendesk-js-library/tools/helpers";
import moment from "@/lib/calendesk-js-library/plugins/moment";

export class GroupRuleHelpers {
  getIntervalsForGroup(group) {
    if (!group || group.length === 0) {
      return [];
    }

    // The intervals should be all the same for the entire group
    return group[0].intervals;
  }
  getFirstGroupRule(group) {
    if (!group || group.length === 0) {
      return null;
    }

    // The intervals should be all the same for the entire group
    return group[0];
  }
  getTitleForGroup(group) {
    if (!group || group.length === 0) {
      return "";
    }
    // Assuming group is sorted and contains consecutive dates with the same intervals
    const startDate = group[0].date; // First date in the group
    const endDate = group[group.length - 1].date; // Last date in the group

    // Check if start and end date are the same
    if (startDate === endDate) {
      return moment(startDate).format(helpers.dayMonthAndYearDate);
    } else {
      // Format start and end dates
      const dateFromDisplay = moment(startDate).format(
        helpers.dayMonthAndYearDate
      );
      const dateToDisplay = moment(endDate).format(helpers.dayMonthAndYearDate);

      // Return the formatted date range title
      return `${dateFromDisplay} - ${dateToDisplay}`;
    }
  }

  getGroupRulesIds(groupedRules) {
    const groupedRulesMap = new Map();
    groupedRules.forEach((gr) => {
      groupedRulesMap.set(gr.id, true);
    });

    return groupedRulesMap;
  }
}
