import store from "@/store";
import helpers, {
  generateRandomString,
} from "@/lib/calendesk-js-library/tools/helpers";
import { RuleGrouper } from "@/components/Dialogs/AvailabilityScheduleDialog/components/RuleGrouper";
import { config } from "@/lib/calendesk-js-library/prototypes/config";
export class ScheduleHelpers {
  constructor() {
    this.configuration = store.getters["setup/getConfiguration"];
  }

  mergeRulesWithDefaults(existingRules) {
    const defaultRules = this.createNewSchedule().rules;
    const mergedRules = [];

    helpers.allWeekdays.forEach((day) => {
      // Filter to get all existing rules for the current day
      const existingRulesForDay = existingRules.filter(
        (rule) =>
          rule.type === helpers.scheduleRuleType.WDAY && rule.wday === day
      );

      if (existingRulesForDay.length > 0) {
        // If there are existing rules for the day, add them all
        mergedRules.push(...existingRulesForDay);
      } else {
        // Otherwise, add a default rule for the day
        const defaultRule = defaultRules.find((rule) => rule.wday === day);
        const ruleWithoutIntervals = { ...defaultRule, intervals: [] }; // Exclude intervals
        mergedRules.push(ruleWithoutIntervals);
      }
    });

    return mergedRules;
  }
  createNewSchedule() {
    const companyWorkingHours = config("company_working_hours");

    const rules = companyWorkingHours.map((dayConfig) => {
      const intervals = dayConfig.is_open
        ? [
            {
              start_time: dayConfig.open,
              end_time: dayConfig.close,
            },
          ]
        : []; // Empty intervals for days the company is closed.

      return {
        type: helpers.scheduleRuleType.WDAY,
        wday: dayConfig.day,
        intervals: intervals,
        id: generateRandomString(12),
      };
    });

    return {
      time_zone: this.configuration.time_zone,
      rules: rules,
    };
  }

  parseSchedule(schedule) {
    RuleGrouper.upgradeSchedule(
      schedule.rules.filter((rule) => rule.type === "date")
    );

    return {
      ...schedule,
      rules: schedule.rules.map((rule) => {
        return {
          ...rule,
          id: generateRandomString(12),
        };
      }),
    };
  }
}
