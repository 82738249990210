import { generateRandomString } from "@/lib/calendesk-js-library/tools/helpers";

export class RuleGrouper {
  constructor(selectedRules) {
    // Ensure rules are sorted by date
    this.selectedRules = selectedRules.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
  }

  // The old version of schedules didn't use group_ids,
  // so we need to bring back the logic and assign the id s first.
  static upgradeSchedule(rules) {
    let currentGroupId = generateRandomString(12);
    let lastRule = null;

    for (const rule of rules) {
      if (!rule.group_id) {
        // Assign or create new group_id based on old grouping criteria
        if (
          lastRule &&
          RuleGrouper.datesAreConsecutive(lastRule.date, rule.date) &&
          RuleGrouper.areIntervalsEqual(lastRule.intervals, rule.intervals)
        ) {
          rule.group_id = currentGroupId;
        } else {
          currentGroupId = generateRandomString(12);
          rule.group_id = currentGroupId;
        }
      }

      lastRule = rule;
    }
  }

  static datesAreConsecutive(date1, date2) {
    const diff = new Date(date2) - new Date(date1);
    return diff === 86400000; // Difference of 24 hours in milliseconds
  }

  static areIntervalsEqual(intervals1, intervals2) {
    return JSON.stringify(intervals1) === JSON.stringify(intervals2);
  }

  // Main function to group rules by group_id
  getAllDateRulesGrouped() {
    let finalGroups = {};

    for (const rule of this.selectedRules) {
      // Use group_id to group rules
      const { group_id } = rule;
      if (!finalGroups[group_id]) {
        finalGroups[group_id] = [];
      }
      finalGroups[group_id].push(rule);
    }

    return Object.entries(finalGroups).map(([group_id, group]) => {
      return group.map((rule) => ({ ...rule, group_id })); // Retain the original group_id for each rule
    });
  }
}
