export class DateGrouper {
  constructor(dates) {
    this.dates = dates;
  }

  groupConsecutiveDates() {
    const sortedDates = this.dates.sort();
    return sortedDates.reduce((acc, current, index, array) => {
      if (index === 0) {
        acc.push([current]);
      } else {
        const previousDate = new Date(array[index - 1]);
        const currentDate = new Date(current);
        const differenceInTime = currentDate - previousDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        if (differenceInDays === 1) {
          acc[acc.length - 1].push(current);
        } else {
          acc.push([current]);
        }
      }
      return acc;
    }, []);
  }
}
