<template>
  <div v-if="displayedLocations.length > 0">
    <v-chip
      v-for="location in displayedLocations"
      :key="location.id"
      x-small
      class="mr-1"
    >
      <location-item class="ml-n1" small :location="location" />
    </v-chip>
    <v-chip v-if="showMoreChip" x-small class="mr-1">
      +{{ extraLocationsCount }} {{ $trans("more") }}...
    </v-chip>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LocationItem from "@/components/LocationItem.vue";

export default {
  components: { LocationItem },
  props: {
    locations: {
      type: Array,
      default: null,
    },
    locationIds: {
      type: Array,
      default: null,
    },
    maxLocationsCount: {
      type: Number,
      default: 5,
    },
    isUsingStaticRuleLocations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parsedLocations: [],
    };
  },
  computed: {
    ...mapGetters({
      cachedLocations: "location/getCachedLocations",
      cachedRuleLocations: "location/getCachedRuleLocations",
    }),
    displayedLocations() {
      return this.parsedLocations.slice(0, this.maxLocationsCount);
    },
    totalLocationsCount() {
      return this.locations
        ? this.locations.length
        : this.locationIds
        ? this.locationIds.length
        : 0;
    },
    showMoreChip() {
      // Determine if the "+more" chip should be shown based on the total count of locations or service IDs
      return this.totalLocationsCount > this.maxLocationsCount;
    },
    extraLocationsCount() {
      return Math.max(this.totalLocationsCount - this.maxLocationsCount, 0);
    },
  },
  mounted() {
    this.loadLocationsById();
  },
  created() {
    // For performance reason, let's delay watchers for later
    this.$nextTick(() => {
      this.$watch("locations", this.loadLocations);
      this.$watch("locationIds", this.loadLocationsById);
    });
  },
  methods: {
    ...mapActions({
      loadLocationsToCacheById: "location/loadLocationsToCacheById",
      loadRuleLocationsToCacheById: "location/loadRuleLocationsToCacheById",
    }),
    loadLocations() {
      if (this.locations) {
        const locations = this.locations;
        this.parsedLocations = locations.slice(0, this.maxLocationsCount);
      }
    },
    loadLocationsById() {
      if (this.locationIds) {
        const loadFunction = this.isUsingStaticRuleLocations
          ? this.loadRuleLocationsToCacheById
          : this.loadLocationsToCacheById;

        loadFunction(this.locationIds).then(() => {
          this.assignCachedLocationsToIds(this.locationIds);
        });
      }
    },
    assignCachedLocationsToIds(locationIds) {
      this.parsedLocations = (
        this.isUsingStaticRuleLocations
          ? this.cachedRuleLocations
          : this.cachedLocations
      )
        .filter((location) => locationIds.includes(location.id))
        .slice(0, this.maxLocationsCount);
    },
  },
};
</script>
