<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container fluid @keydown.enter.prevent="handleSave">
      <v-row>
        <v-col cols="12" class="px-0">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="selectedName"
                  :rules="[rules.required, rules.maxChars(255)]"
                  :label="$trans('schedule_name')"
                  :hint="$trans('employee_schedule_hint')"
                  persistent-hint
                  outlined
                  :disabled="isSaving"
                />
              </v-col>
              <v-col cols="12" md="4">
                <c-time-zone-autocomplete
                  v-model="selectedTimeZone"
                  :label="$trans('c_time_zone_autocomplete_label')"
                  :rules="[rules.required]"
                  :disabled="isSaving"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h5">{{ $trans("weekday_rules_title") }}</v-col>
      </v-row>
      <v-row class="five-cols">
        <v-col v-for="day in $helpers.allWeekdays" :key="day">
          <week-day-rules-wrapper
            class="py-2 rounded snow_gray"
            :rules="getRulesForDay(day)"
            :wday="day"
            @change="weekDayRulesChanged(day, $event)"
            @openAvailabilityRuleConditionsDialog="
              openAvailabilityRuleConditionsDialog('rule', $event)
            "
            @copyHoursFromWeekDay="copyHoursFromWeekDayToRule"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-h5 pt-8">{{
          $trans("exceptions_date_range_title")
        }}</v-col>
      </v-row>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("exceptions_date_range_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-rules-wrapper
            class="py-2"
            :rules="getAllDateRules"
            @change="dateRulesChanged($event)"
            @openAvailabilityRuleConditionsDialog="
              openAvailabilityRuleConditionsDialog('rule', $event)
            "
            @editGroupRules="editGroupRules($event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center">
          <v-tooltip bottom max-width="400">
            <template #activator="{ on }">
              <v-btn
                text
                class="mx-1 ml-2"
                outlined
                color="secondary"
                v-on="on"
                @click.native="openDateRulesCalendarPicker"
              >
                <div class="d-flex align-center">
                  <v-icon left>$playlist-plus</v-icon>
                  {{ $trans("add_availability_schedule_exception_title") }}
                </div>
              </v-btn>
            </template>
            {{ $trans("add_new_date_range_button_tooltip") }}
          </v-tooltip>
        </v-col>
      </v-row>

      <availability-rule-conditions-dialog
        v-model="showAvailabilityRuleConditionsDialog"
        :service-ids="availabilityRuleConditionsDialogServiceIds"
        :location-ids="availabilityRuleConditionsDialogLocationIds"
        @close="closeAvailabilityRuleConditionsDialog($event)"
      />

      <date-rules-calendar-picker
        v-model="showDateRulesCalendarPicker"
        :calendar-service-ids="selectedCalendarServiceIds"
        :calendar-location-ids="selectedCalendarLocationIds"
        :calendar-dates="selectedCalendarDates"
        :calendar-hours="selectedCalendarHours"
        :updating-rules="selectedCalendarRulesForUpdate"
        @openAvailabilityRuleConditionsDialog="
          openAvailabilityRuleConditionsDialog('calendar', $event)
        "
        @close="closeDateRulesCalendarPicker($event)"
        @copyHoursFromWeekDay="copyHoursFromWeekDayToSelectedCalendarHours"
      />
    </v-container>
  </v-form>
</template>

<script>
import WeekDayRulesWrapper from "@/components/Dialogs/AvailabilityScheduleDialog/components/WeekDayRulesWrapper.vue";
import sharedRuleActions from "@/components/Dialogs/AvailabilityScheduleDialog/mixins/sharedRuleActions";
import DateRulesWrapper from "@/components/Dialogs/AvailabilityScheduleDialog/components/DateRulesWrapper.vue";
import AvailabilityRuleConditionsDialog from "@/components/Dialogs/AvailabilityScheduleDialog/components/AvailabilityRuleConditionsDialog.vue";
import DateRulesCalendarPicker from "@/components/Dialogs/AvailabilityScheduleDialog/components/DateRulesCalendarPicker.vue";
import { ScheduleHelpers } from "@/components/Dialogs/AvailabilityScheduleDialog/components/ScheduleHelpers";
import { DateGrouper } from "@/components/Dialogs/AvailabilityScheduleDialog/components/DateGrouper";
import {
  cloneObject,
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";
import CTimeZoneAutocomplete from "@/views/dashboard/pages/Settings/components/CTimeZoneAutocomplete.vue";
import {
  required,
  maxChars,
} from "@/lib/calendesk-js-library/forms/validators";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { mapActions } from "vuex";

export default {
  name: "AvailabilityScheduleDialog",
  components: {
    CalendeskInformationMessage,
    CTimeZoneAutocomplete,
    DateRulesCalendarPicker,
    AvailabilityRuleConditionsDialog,
    DateRulesWrapper,
    WeekDayRulesWrapper,
  },
  mixins: [sharedRuleActions],
  data() {
    return {
      scheduleHelpers: new ScheduleHelpers(),
      schedule: null,
      selectedTimeZone: null,
      selectedName: null,
      showDateRulesCalendarPicker: false,
      showAvailabilityRuleConditionsDialog: false,
      selectedCalendarDates: [],
      selectedCalendarHours: [],
      selectedCalendarServiceIds: [],
      selectedCalendarLocationIds: [],
      selectedCalendarRulesForUpdate: null,
      availabilityRuleConditionsDialogServiceIds: [],
      availabilityRuleConditionsDialogLocationIds: [],
      whoIsOpeningAvailabilityRuleConditionsDialog: null,
      ruleForAvailabilityRuleConditionsDialog: null,
      rules: {
        required,
        maxChars,
      },
      isSaving: false,
    };
  },
  computed: {
    getAllDateRules() {
      return this.schedule.rules.filter(
        (rule) => rule.type === this.$helpers.scheduleRuleType.DATE,
      );
    },
    cleanedRules() {
      return this.schedule
        ? this.schedule.rules
            .filter((rule) => rule.intervals.length > 0)
            .map(({ id, ...rest }) => rest)
        : [];
    },
  },
  created() {
    this.loadSchedule(this.getDialog.data || null);
  },
  methods: {
    ...mapActions({
      createSchedule: "availabilitySchedule/create",
      updateSchedule: "availabilitySchedule/update",
      refreshSchedulesList: "availabilitySchedule/refreshSchedulesList",
      reloadSchedules: "availabilitySchedule/fetchAll",
    }),
    loadSchedule(schedule = null) {
      if (schedule) {
        this.schedule = this.scheduleHelpers.parseSchedule(schedule);
      } else {
        this.schedule = this.scheduleHelpers.createNewSchedule();
      }

      const dayRules = this.scheduleHelpers.mergeRulesWithDefaults(
        this.schedule.rules.filter(
          (rule) => rule.type === this.$helpers.scheduleRuleType.WDAY,
        ),
      );

      this.schedule.rules = dayRules.concat(
        this.schedule.rules.filter(
          (rule) => rule.type === this.$helpers.scheduleRuleType.DATE,
        ),
      );

      this.selectedTimeZone = this.schedule.timezone || userTimeZone();
      this.selectedName = this.schedule.name || this.$trans("work_schedule");

      this.resetCalendar();
    },
    resetCalendar() {
      this.selectedCalendarDates = [];
      this.selectedCalendarHours = [
        {
          start_time: this.configuration.calendar_time.from,
          end_time: this.configuration.calendar_time.to,
        },
      ];

      this.selectedCalendarServiceIds = [];
      this.selectedCalendarLocationIds = [];
      this.whoIsOpeningAvailabilityRuleConditionsDialog = null;
      this.ruleForAvailabilityRuleConditionsDialog = null;
      this.selectedCalendarRulesForUpdate = null;
    },
    getRulesForDay(dayOfWeek) {
      return this.schedule.rules.filter(
        (rule) =>
          rule.type === this.$helpers.scheduleRuleType.WDAY &&
          rule.wday === dayOfWeek,
      );
    },
    getRulesForDate(date) {
      return this.schedule.rules.filter(
        (rule) =>
          rule.type === this.$helpers.scheduleRuleType.DATE &&
          rule.date === date,
      );
    },
    async handleSave() {
      if (this.$refs.form.validate()) {
        if (!(this.cleanedRules.length > 0 || this.cleanedRules.length > 0)) {
          errorNotification("fill_availability_schedules", {}, false);
          return;
        }

        try {
          this.isLoading = true;
          this.$emit("ctaButtonLoading", true);
          let schedule = null;
          if (this.getDialog.data) {
            schedule = await this.update();
          } else {
            schedule = await this.add();
          }

          this.loadSchedule(schedule);
          await this.refreshSchedulesList();
          await this.reloadSchedules();

          successNotification("update_success");
          this.isLoading = false;
          this.$emit("ctaButtonLoading", false);
          this.$emit("close");
        } catch (error) {
          errorNotification(null, error, false);
          this.isLoading = false;
          this.$emit("ctaButtonLoading", false);
        }
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    async add() {
      return this.createSchedule({
        name: this.selectedName,
        rules: this.cleanedRules,
        timezone: this.selectedTimeZone,
      });
    },
    async update() {
      return this.updateSchedule({
        id: this.schedule.id,
        name: this.selectedName,
        rules: this.cleanedRules,
        timezone: this.selectedTimeZone,
      });
    },
    weekDayRulesChanged(dayOfTheWeek, updatedRules) {
      const filteredRules = this.schedule.rules.filter((rule) => {
        return !(
          rule.type === this.$helpers.scheduleRuleType.WDAY &&
          rule.wday === dayOfTheWeek
        );
      });

      this.schedule.rules = [...filteredRules, ...updatedRules];
    },
    dateRulesChanged(updatedRules) {
      const filteredRules = this.schedule.rules.filter((rule) => {
        return !(rule.type === this.$helpers.scheduleRuleType.DATE);
      });

      this.schedule.rules = [...filteredRules, ...updatedRules];
    },
    openAvailabilityRuleConditionsDialog(whoIsAsking, rule = null) {
      this.availabilityRuleConditionsDialogServiceIds =
        rule && rule.service_ids ? rule.service_ids : [];
      this.availabilityRuleConditionsDialogLocationIds =
        rule && rule.location_ids ? rule.location_ids : [];
      this.showAvailabilityRuleConditionsDialog = true;
      this.whoIsOpeningAvailabilityRuleConditionsDialog = whoIsAsking;
      this.ruleForAvailabilityRuleConditionsDialog = rule;
    },
    closeAvailabilityRuleConditionsDialog(data) {
      this.showAvailabilityRuleConditionsDialog = false;
      this.availabilityRuleConditionsDialogLocationIds = [];
      this.availabilityRuleConditionsDialogServiceIds = [];

      if (data.isSaving) {
        switch (this.whoIsOpeningAvailabilityRuleConditionsDialog) {
          case "calendar":
            this.selectedCalendarLocationIds = data.location_ids;
            this.selectedCalendarServiceIds = data.service_ids;
            break;
          case "rule":
            if (this.ruleForAvailabilityRuleConditionsDialog) {
              this.schedule.rules = this.schedule.rules.map((rule) => {
                const isDateGroup =
                  this.ruleForAvailabilityRuleConditionsDialog.type ===
                    this.$helpers.scheduleRuleType.DATE &&
                  this.ruleForAvailabilityRuleConditionsDialog.group_id;

                if (
                  (isDateGroup &&
                    this.ruleForAvailabilityRuleConditionsDialog.group_id ===
                      rule.group_id) ||
                  (!isDateGroup &&
                    this.ruleForAvailabilityRuleConditionsDialog.id === rule.id)
                ) {
                  const updatedRule = {
                    ...rule,
                  };

                  if (data.location_ids) {
                    updatedRule.location_ids = data.location_ids;
                  }

                  if (data.service_ids) {
                    updatedRule.service_ids = data.service_ids;
                  }

                  return updatedRule;
                }

                return rule;
              });
            }
            break;
        }
      }
    },
    openDateRulesCalendarPicker() {
      this.resetCalendar();
      this.showDateRulesCalendarPicker = true;
    },
    closeDateRulesCalendarPicker(data) {
      if (data.isAdding || data.isUpdating) {
        if (data.isUpdating && data.updatingRules) {
          const updatingRuleIds = data.updatingRules.map((rule) => rule.id);

          // Identify and remove the rules by `id` associated with updating rules
          // We will add them again
          this.schedule.rules = this.schedule.rules.filter(
            (rule) => !updatingRuleIds.includes(rule.id),
          );
        }

        const dateGroups = new DateGrouper(
          data.selectedCalendarDates,
        ).groupConsecutiveDates();

        // Iterate over the grouped dates, assigning a unique group ID to each group
        dateGroups.forEach((group) => {
          const groupId = "group_" + this.generateRandomString(12);

          group.forEach((date) => {
            const rule = this.createRule(
              this.getRulesForDate(date),
              this.$helpers.scheduleRuleType.DATE,
              date,
              data.selectedCalendarHours,
              this.selectedCalendarServiceIds,
              this.selectedCalendarLocationIds,
              groupId,
            );

            this.schedule.rules.push(rule);
          });
        });
      }

      this.resetCalendar();
      this.showDateRulesCalendarPicker = false;
    },
    editGroupRules(rules) {
      if (rules && rules.length > 0) {
        const firstRule = rules[0];
        this.selectedCalendarDates = rules.map((rule) => rule.date);
        this.selectedCalendarHours = firstRule.intervals;

        this.selectedCalendarServiceIds = firstRule.service_ids;
        this.selectedCalendarLocationIds = firstRule.location_ids;
        this.selectedCalendarRulesForUpdate = rules;

        this.showDateRulesCalendarPicker = true;
      }
    },
    copyHoursFromWeekDayToSelectedCalendarHours(weekday) {
      let day = this.cleanedRules.find((rule) => rule.wday === weekday);

      if (day) {
        day = cloneObject(day);
        this.selectedCalendarHours = day.intervals ? [...day.intervals] : [];
        this.selectedCalendarServiceIds = day.service_ids
          ? [...day.service_ids]
          : [];
        this.selectedCalendarLocationIds = day.location_ids
          ? [...day.location_ids]
          : [];
      } else {
        this.selectedCalendarHours = [];
        this.selectedCalendarServiceIds = [];
        this.selectedCalendarLocationIds = [];
      }
    },
    copyHoursFromWeekDayToRule(data) {
      const weekdayTo = data.weekdayTo;
      const weekdayFrom = data.weekdayFrom;

      if (weekdayTo && weekdayFrom) {
        let requestedWeekdays = this.schedule.rules.filter(
          (rule) => rule.wday === weekdayFrom,
        );

        requestedWeekdays = cloneObject(requestedWeekdays);
        let updatedRules = requestedWeekdays.map((rule) => ({
          ...rule,
          id: this.generateRandomString(12),
          type: this.$helpers.scheduleRuleType.WDAY,
          wday: weekdayTo,
        }));

        this.weekDayRulesChanged(weekdayTo, cloneObject(updatedRules));
      }
    },
  },
};
</script>
