import {
  addMinutes,
  parseTime,
} from "@/lib/calendesk-js-library/tools/helpers";
import { mapGetters } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
  },
  methods: {
    createRule(
      rules,
      ruleType,
      identifier,
      intervals = null,
      serviceIds = null,
      locationIds = null,
      groupId = null
    ) {
      const defaultInterval = {
        start_time: this.configuration.calendar_time.from,
        end_time: this.configuration.calendar_time.to,
      };

      let newStartTime = defaultInterval.start_time;
      let newEndTime = defaultInterval.end_time;

      if (!intervals) {
        // Determine if there is a last interval to base the new rule's times on
        const lastRule = rules[rules.length - 1];
        const hasValidLastInterval =
          lastRule && lastRule.intervals && lastRule.intervals.length > 0;
        const lastInterval = hasValidLastInterval
          ? lastRule.intervals[lastRule.intervals.length - 1]
          : null;

        // If there's a valid last interval, adjust the new start and end times accordingly
        if (lastInterval) {
          const parsedEndTime = parseTime(lastInterval.end_time);
          newStartTime = lastInterval.end_time;
          newEndTime = addMinutes(
            lastInterval.end_time,
            parsedEndTime <= 1320 ? 60 : 5
          );
        }
      }

      const result = {
        type: ruleType,
        [ruleType === this.$helpers.scheduleRuleType.DATE
          ? this.$helpers.scheduleRuleType.DATE
          : this.$helpers.scheduleRuleType.WDAY]: identifier,
        intervals: intervals || [
          { start_time: newStartTime, end_time: newEndTime },
        ],
        id: this.generateRandomString(12),
      };

      if (groupId) {
        result.group_id = groupId;
      }

      if (serviceIds && serviceIds.length > 0) {
        result.service_ids = serviceIds;
      }

      if (locationIds && locationIds.length > 0) {
        result.location_ids = locationIds;
      }

      return result;
    },
  },
};
