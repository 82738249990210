<template>
  <v-dialog
    v-model="dialog"
    width="390px"
    scrollable
    transition="scale-transition"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
  >
    <v-card
      v-if="dialog"
      :min-height="$vuetify.breakpoint.height / 2"
      class="white"
    >
      <v-card-title>
        {{ $trans("add_availability_schedule_conditions_title") }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-2">
        <v-container>
          <v-row>
            <v-col class="fill-height">
              <services-field
                v-model="selectedServiceIds"
                small-chips
                multiple
                deletable-chips
                return-ids
              />
            </v-col>
          </v-row>
          <v-row
            v-if="
              getAdminConfiguration &&
              getAdminConfiguration.schedules_v2_enabled
            "
          >
            <v-col class="fill-height">
              <location-rule-select-field
                :location-ids="selectedLocationIds"
                small-chips
                multiple-selection
                deletable-chips
                hide-actions
                return-ids
                @change="updateSelected"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <calendesk-information-message>
                {{ $trans("availability_schedule_conditions_description") }}
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn text color="light" @click="closeDialog(false)">
                {{ $trans("cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="closeDialog(true)">
                {{ $trans("ok") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import LocationRuleSelectField from "@/components/Forms/LocationRuleSelectField.vue";
import { mapGetters } from "vuex";

export default {
  name: "AvailabilityRuleConditionsDialog",
  components: {
    LocationRuleSelectField,
    CalendeskInformationMessage,
    ServicesField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    serviceIds: {
      type: Array,
      default: () => [],
    },
    locationIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      selectedServiceIds: [],
      selectedLocationIds: [],
    };
  },
  computed: {
    ...mapGetters({
      getAdminConfiguration: "setup/getAdminConfiguration",
    }),
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("input", val);
    },
    serviceIds(val) {
      this.selectedServiceIds = val;
    },
    locationIds(val) {
      this.selectedLocationIds = val;
    },
  },
  methods: {
    closeDialog(commit) {
      this.$emit("close", {
        isSaving: commit,
        service_ids: this.selectedServiceIds,
        location_ids: this.selectedLocationIds,
      });
      this.dialog = false;
    },
    updateSelected(locationIds) {
      this.selectedLocationIds = locationIds;
    },
  },
};
</script>
