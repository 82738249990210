<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-menu
        v-model="copyHoursMenuIsActive"
        :close-on-content-click="true"
        offset-y
      >
        <template #activator="{ on: menuOn }">
          <v-btn
            id="copy_hours_button"
            icon
            small
            text
            class="mx-1 ml-1"
            v-on="{ ...on, ...menuOn }"
            @click="copyHoursMenuIsActive = !copyHoursMenuIsActive"
          >
            <v-icon>$copy</v-icon>
          </v-btn>
        </template>

        <v-list class="event-list-menu">
          <v-list-item
            v-for="day in $helpers.allWeekdays"
            :key="day"
            @click="copyHoursFromWeekDay(day)"
          >
            <v-list-item-title class="d-flex align-center">
              {{ $trans("copy_hours_from_" + day + "_item") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    {{ $trans("copy_hours_button_title") }}
  </v-tooltip>
</template>
<script>
export default {
  data() {
    return {
      copyHoursMenuIsActive: false,
    };
  },
  methods: {
    copyHoursFromWeekDay(weekday) {
      this.$emit("copyHoursFromWeekDay", weekday);
    },
  },
};
</script>
