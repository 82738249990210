<template>
  <div class="d-flex align-center">
    <v-icon
      v-if="getIconForType(location.type)"
      :class="small ? 'mr-1' : 'mr-2'"
      color="primary"
      :size="small ? 15 : 20"
    >
      {{ getIconForType(location.type) }}
    </v-icon>
    <img
      v-else
      height="15"
      :src="getImageForType(location.type)"
      :alt="$trans('service_location_' + location.type)"
      :class="{
        'extra-image-padding': small,
        'mr-1 ml-1': small,
        'mr-2 ml-1': !small,
      }"
    />
    <span class="text-truncate">
      <template v-if="!isStaticRuleLocation(location.type)"
        >({{ $trans("id") }}: {{ location.id }})</template
      >
      <template v-if="location.name">
        {{ location.name }}
      </template>
      <template v-else>
        {{ $trans("service_location_" + location.type) }}
      </template>
      <template v-if="location.data"> ({{ location.data }})</template>
    </span>
  </div>
</template>
<script>
import serviceLocationActions from "@/calendesk/mixins/serviceLocationActions";

export default {
  mixins: [serviceLocationActions],
  props: {
    location: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.extra-image-padding {
  padding: 2px 0;
}
</style>
