<template>
  <div>
    <div>
      <div v-if="ruleHasConditions">
        <div
          v-if="selectedRule.service_ids && selectedRule.service_ids.length > 0"
          class="pb-2"
        >
          <div>{{ $trans("services") }}:</div>
          <service-chips :service-ids="selectedRule.service_ids" />
        </div>
        <div
          v-if="
            selectedRule.location_ids && selectedRule.location_ids.length > 0
          "
          class="pb-2"
        >
          <div>{{ $trans("service_locations") }}:</div>
          <location-chips
            :location-ids="selectedRule.location_ids"
            is-using-static-rule-locations
          />
        </div>
      </div>
      <div class="text-center mt-2">
        <v-tooltip bottom max-width="400">
          <template #activator="{ on }">
            <v-btn
              small
              text
              color="secondary"
              outlined
              v-on="on"
              @click="$emit('open', rule)"
            >
              <div v-if="ruleHasConditions" class="d-flex align-center">
                <v-icon left>$pencil</v-icon>
                {{ $trans("edit_availability_schedule_conditions_title") }}
              </div>
              <div v-else class="d-flex align-center">
                <v-icon left>$plus</v-icon>
                {{ $trans("add_availability_schedule_conditions_title") }}
              </div>
            </v-btn>
          </template>
          {{ $trans("availability_schedule_conditions_description") }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceChips from "@/components/ServiceChips.vue";
import LocationChips from "@/components/LocationChips.vue";

export default {
  components: { LocationChips, ServiceChips },
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedRule: this.rule,
    };
  },
  computed: {
    ruleHasConditions() {
      return (
        (this.selectedRule.location_ids &&
          this.selectedRule.location_ids.length > 0) ||
        (this.selectedRule.service_ids &&
          this.selectedRule.service_ids.length > 0)
      );
    },
  },
  watch: {
    rule: {
      handler(val) {
        if (val) {
          this.selectedRule = this.rule;
        }
      },
      deep: true,
    },
  },
};
</script>
