import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  props: {
    rules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedRules: [],
    };
  },
  watch: {
    rules: {
      handler(newRules) {
        if (!this.deepEquals(newRules, this.selectedRules)) {
          this.assignRules();
        }
      },
      deep: true,
    },
  },
  created() {
    this.assignRules();
  },
  methods: {
    deepEquals(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    assignRules() {
      this.selectedRules = this.$helpers.cloneObject(this.rules);
    },
    change() {
      this.$emit("change", this.$helpers.cloneObject(this.selectedRules));
    },
    openAvailabilityRuleConditionsDialog(rule) {
      this.$emit("openAvailabilityRuleConditionsDialog", rule);
    },
  },
};
