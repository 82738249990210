<template>
  <v-container fluid>
    <v-row class="five-cols">
      <v-col v-for="(group, index) in getAllDateRulesGrouped" :key="index">
        <div class="snow_gray pa-2 rounded">
          <div class="d-flex align-center justify-center mb-2">
            <div
              class="text-h6 text-center pointer"
              @click="editGroupRules(group.rules)"
            >
              {{ group.title }}
            </div>
            <v-btn
              icon
              small
              text
              @click.native="deleteGroupRules(group.rules)"
            >
              <v-icon>$trash-default</v-icon>
            </v-btn>
          </div>

          <time-range-interval
            v-if="group.interval && group.interval.length > 0"
            hide-delete-button-for-only-one-interval
            :rule="group.firstRule"
            @change="updateGroupRules(group.rules, $event, 'intervals')"
          />

          <conditions-manager
            :rule="group.firstRule"
            @open="openAvailabilityRuleConditionsDialog($event)"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TimeRangeInterval from "@/components/Dialogs/AvailabilityScheduleDialog/components/TimeRangeInterval.vue";
import { RuleGrouper } from "@/components/Dialogs/AvailabilityScheduleDialog/components/RuleGrouper";
import { GroupRuleHelpers } from "@/components/Dialogs/AvailabilityScheduleDialog/components/GroupRuleHelpers";
import sharedRuleWrapperActions from "@/components/Dialogs/AvailabilityScheduleDialog/mixins/sharedRuleWrapperActions";
import ConditionsManager from "@/components/Dialogs/AvailabilityScheduleDialog/components/ConditionsManager.vue";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";

export default {
  components: {
    ConditionsManager,
    TimeRangeInterval,
  },
  mixins: [sharedRuleWrapperActions],
  data() {
    return {
      groupRuleHelper: new GroupRuleHelpers(),
    };
  },
  computed: {
    getAllDateRulesGrouped() {
      const grouper = new RuleGrouper(this.selectedRules);

      return grouper.getAllDateRulesGrouped().map((groupedRules) => {
        return {
          rules: groupedRules,
          firstRule: this.groupRuleHelper.getFirstGroupRule(groupedRules),
          interval: this.groupRuleHelper.getTitleForGroup(groupedRules),
          title: this.groupRuleHelper.getTitleForGroup(groupedRules),
        };
      });
    },
  },
  methods: {
    updateRuleLocations(indexToUpdate, rule, locationIds) {
      this.updateRule(indexToUpdate, { ...rule, location_ids: locationIds });
    },
    updateRuleServices(indexToUpdate, rule, serviceIds) {
      this.updateRule(indexToUpdate, { ...rule, service_ids: serviceIds });
    },
    updateGroupRules(groupedRules, updatedFirstRule, key) {
      const groupedRulesMap =
        this.groupRuleHelper.getGroupRulesIds(groupedRules);

      this.selectedRules = this.selectedRules.map((rule) => {
        if (groupedRulesMap.has(rule.id)) {
          return { ...rule, [key]: updatedFirstRule[key] };
        }
        return rule;
      });

      // Call the change method after updating selectedRules
      this.change();
    },

    deleteGroupRules(groupedRules) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("delete_date_range_warning_title"),
        warning: this.$trans("delete_date_range_warning_description"),
        confirmationText: this.$trans("delete"),
        confirmAction: () => {
          const groupedRulesMap =
            this.groupRuleHelper.getGroupRulesIds(groupedRules);
          this.selectedRules = this.selectedRules.filter(
            (rule) => !groupedRulesMap.has(rule.id)
          );

          this.change();
          this.closeConfirmDialog();
        },
      });
    },
    editGroupRules(groupedRules) {
      this.$emit("editGroupRules", this.$helpers.cloneObject(groupedRules));
    },
  },
};
</script>
