<template>
  <div>
    <div class="d-flex align-center justify-center text-h6">
      {{ $trans(wday) }}
      <copy-hours-button @copyHoursFromWeekDay="copyHoursFromWeekDay" />
    </div>
    <div v-for="rule in rulesWithIntervals" :key="`${rule.id}`" class="my-2">
      <time-range-interval :rule="rule" @change="updateRule(rule, $event)" />
      <conditions-manager
        class="pa-2"
        :rule="rule"
        @open="openAvailabilityRuleConditionsDialog(rule)"
      />
      <v-divider class="mx-2 my-4" />
    </div>
    <div class="text-center">
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-btn
            icon
            small
            text
            class="mx-1 ml-2"
            v-on="on"
            @click.native="handleAddRuleButtonClick(wday)"
          >
            <v-icon>$playlist-plus</v-icon>
          </v-btn>
        </template>
        {{ $trans("add_hours_range_button_tooltip") }}
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import TimeRangeInterval from "@/components/Dialogs/AvailabilityScheduleDialog/components/TimeRangeInterval.vue";
import sharedRuleActions from "@/components/Dialogs/AvailabilityScheduleDialog/mixins/sharedRuleActions";
import sharedRuleWrapperActions from "@/components/Dialogs/AvailabilityScheduleDialog/mixins/sharedRuleWrapperActions";
import ConditionsManager from "@/components/Dialogs/AvailabilityScheduleDialog/components/ConditionsManager.vue";
import CopyHoursButton from "@/components/Dialogs/AvailabilityScheduleDialog/components/CopyHoursButton.vue";

export default {
  components: {
    CopyHoursButton,
    ConditionsManager,
    TimeRangeInterval,
  },
  mixins: [sharedRuleActions, sharedRuleWrapperActions],
  props: {
    wday: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    rulesWithIntervals() {
      return this.selectedRules.filter(
        (r) => r.intervals && r.intervals.length > 0
      );
    },
  },
  methods: {
    updateRuleLocations(indexToUpdate, rule, locationIds) {
      this.updateRule(indexToUpdate, { ...rule, location_ids: locationIds });
    },
    updateRuleServices(indexToUpdate, rule, serviceIds) {
      this.updateRule(indexToUpdate, { ...rule, service_ids: serviceIds });
    },
    updateRule(ruleToUpdate, newRule) {
      this.selectedRules = this.selectedRules.map((rule) => {
        if (ruleToUpdate.id === rule.id) {
          return { ...newRule, id: ruleToUpdate.id };
        }
        return rule;
      });

      this.change();
    },
    handleAddRuleButtonClick(weekDay) {
      const rule = this.createRule(
        this.selectedRules,
        this.$helpers.scheduleRuleType.WDAY,
        weekDay
      );
      this.selectedRules.push(rule);

      this.change();
    },
    copyHoursFromWeekDay(weekday) {
      this.$emit("copyHoursFromWeekDay", {
        weekdayFrom: weekday,
        weekdayTo: this.wday,
      });
    },
  },
};
</script>
