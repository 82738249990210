var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"autocomplete",staticClass:"cd-fake-outer",attrs:{"search-input":_vm.searchText,"no-filter":"","outlined":"","items":_vm.getAllItems,"label":!_vm.selectedItems || (_vm.multipleSelection && _vm.selectedItems.length === 0)
      ? _vm.label || _vm.$trans('service_location')
      : _vm.label || _vm.$trans('service_location'),"multiple":_vm.multipleSelection,"hide-details":"auto","item-text":"id","dense":_vm.dense,"rules":_vm.rules,"loading":_vm.isLoading,"disabled":_vm.disabled,"no-data-text":_vm.hideActions ? _vm.$trans('nothing_here') : _vm.$trans('add_locations_with_plus'),"placeholder":_vm.$trans('autocomplete_hint'),"return-object":"","clearable":_vm.clearable,"attach":'#' + _vm.uniqueId},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event},"input":_vm.onInputChange,"blur":_vm.handleInputBlur},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [(_vm.canLoadMorePages)?_c('v-list-item',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center justify-center pointer my-2"},[_c('span',{staticClass:"text-break cd-v-select-line-height text-wrap"},[_vm._v(" "+_vm._s(_vm.$trans("loading"))+" ")])])])],1):_vm._e()]},proxy:true},{key:"selection",fn:function(data){return [_c('div',{staticClass:"d-flex align-center pointer",staticStyle:{"max-width":"99%"}},[(data.index < _vm.maxItemsInSelectField)?[(_vm.chips || _vm.smallChips)?_c('v-chip',{attrs:{"disabled":_vm.disabled,"small":_vm.smallChips,"close":_vm.deletableChips},on:{"click:close":function($event){return _vm.removeChip(data.item)}}},[(_vm.getIconForType(data.item.type))?_c('v-icon',{staticClass:"mr-1 ml-n1",attrs:{"color":"primary","size":"20"}},[_vm._v(" "+_vm._s(_vm.getIconForType(data.item.type))+" ")]):_c('img',{class:{
              'mr-1 ml-n1': _vm.smallChips,
            },attrs:{"height":"15","src":_vm.getImageForType(data.item.type),"alt":_vm.$trans('service_location_' + data.item.type)}}),_c('span',{staticClass:"text-truncate"},[(!_vm.isStaticRuleLocation(data.item.type))?[_vm._v(" ("+_vm._s(_vm.$trans("id"))+": "+_vm._s(data.item.id)+") ")]:_vm._e(),(data.item.name)?[_vm._v(_vm._s(data.item.name))]:[_vm._v(" "+_vm._s(_vm.$trans("service_location_" + data.item.type))+" ")],(data.item.data)?[_vm._v(" ("+_vm._s(data.item.data)+")")]:_vm._e()],2)],1):[_c('span',{staticClass:"text-truncate"},[(!_vm.isStaticRuleLocation(data.item.type))?[_vm._v(" ("+_vm._s(_vm.$trans("id"))+": "+_vm._s(data.item.id)+") ")]:_vm._e(),(data.item.name)?[_vm._v(_vm._s(data.item.name))]:[_vm._v(" "+_vm._s(_vm.$trans("service_location_" + data.item.type))+" ")],(data.item.data)?[_vm._v(" ("+_vm._s(data.item.data)+")")]:_vm._e()],2)]]:_vm._e(),(_vm.multipleSelection && data.index === _vm.maxItemsInSelectField)?_c('div',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedItems.length - _vm.maxItemsInSelectField)+" "+_vm._s(_vm.$trans("more"))+") ")]):_vm._e()],2)]}},{key:"item",fn:function(ref){
            var item = ref.item;
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:{
        'primary--text v-list-item--active v-list-item--highlighted':
          _vm.isItemSelected(item),
      },on:{"click":function($event){$event.stopPropagation();return _vm.toggleItem(item)}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"d-flex justify-start"},[(_vm.getIconForType(item.type))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.getIconForType(item.type))+" ")]):_c('img',{attrs:{"height":"20","src":_vm.getImageForType(item.type),"alt":_vm.$trans('service_location_' + item.type)}})],1),_c('v-list-item-title',[_c('span',{staticClass:"text-truncate"},[(!_vm.isStaticRuleLocation(item.type))?[_vm._v(" ("+_vm._s(_vm.$trans("id"))+": "+_vm._s(item.id)+") ")]:_vm._e(),(item.name)?[_vm._v(_vm._s(item.name))]:[_vm._v(" "+_vm._s(_vm.$trans("service_location_" + item.type))+" ")],(item.data)?[_vm._v(" ("+_vm._s(item.data)+")")]:_vm._e()],2)])],1)]}},{key:"append-outer",fn:function(){return [_c('div',{attrs:{"id":_vm.uniqueId}}),(!_vm.hideActions)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({staticClass:"outlined-icon outlined-icon-top-0 ml-2",on:{"click":function($event){return _vm.handleAddLocation(true)}}},on),[_c('v-icon',[_vm._v("$plus")])],1)]}}],null,false,634824651)},[_vm._v(" "+_vm._s(_vm.$trans("add"))+" ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.reducedSelectedItems),callback:function ($$v) {_vm.reducedSelectedItems=$$v},expression:"reducedSelectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }